<template>
  <div class="video_box" v-if="visible" @click.self="hideVideo()">
    <div :id="'player-con' + reportId" :style="'width:'+imgWidth+'px;height:'+imgHeight+'px'"></div>
  </div>
</template>

<script>
import axios from 'axios'

var videoPlay = null

export default {
  components: {},
  props:{
    videoId:{
      type:String,
      default:''
    },
    reportId:{
      type:String,
      default:''
    },
    videoCoverImg:{
      type:String,
      default:''
    },
    from:{
      type:String,
      default:'mobile'
    }
  },
  data () {
    return {
      imgWidth: window.screen.width,
      imgHeight: (window.screen.width * 3) / 4,
      visible:false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    hideVideo(){
      this.visible = false
    },
    getVideoDetail(){
      const that = this
      if(that.from=='pc'){
        that.imgWidth = (window.screen.width * 3) / 4
        that.imgHeight = (window.screen.height * 3) /4
      }
      const data = {videoId: that.videoId}
      axios.post(process.env.VUE_APP_VIDEO_URL + 'api/File/GetPlayInfo',data).then(function (res) {
        if (res.data) {
          that.visible = true
          setTimeout(function () {
            that.privewVideoPlay(that.reportId, res.data.data, that.videoCoverImg);
          }, 500)
        }
      })
    },
    privewVideoPlay(id, video, carPicture){
      let props = {
        id: 'player-con' + id,
        width: '100%',
        height: '100%',
        cover: carPicture,
        source: video.playInfoList[0].playURL,
        autoplay: true,
        preload: false,
        isLive: false,
        fullscreen:false,
      }

      videoPlay = new Aliplayer(props,function(player){

      });
    },
    
    // 关闭播放
    closePlayer(){
      if (videoPlay) {
        videoPlay.pause();
        videoPlay.destroy();
        videoPlay = null;
      }
    },
  }
}

</script>
<style lang='scss' scoped>
.video_box{
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top:0;
  left: 0;
  z-index: 11;
}
</style>