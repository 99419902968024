<!--
 * @名称: 导航组建
 * @Descripttion: ml 创建
 * @Author: ml
 * @Date: 2019-12-16 11:50:36
 * @LastEditors  : min
 * @LastEditTime : 2022-11-30 10:20:30
 -->

<template>
  <div class="ml_tabbar_list" v-show="isShowTabBar">
    <div class="ml_tabbar_list_item" @click="goToTab('wgTab',0)">
      <span :class="{'ml_tabbar_list_item_active':tabbarIndex==0}">
        外观
        <template v-if="detail.wgcount">({{detail.wgcount}})</template>
      </span>
    </div>
    <div class="ml_tabbar_list_item" @click="goToTab('gjTab',1)">
      <span :class="{'ml_tabbar_list_item_active':tabbarIndex==1}">
        骨架
        <template v-if="detail.gjcount">({{detail.gjcount}})</template></span>
    </div>
    <div class="ml_tabbar_list_item" @click="goToTab('nsTab',2)">
      <span :class="{'ml_tabbar_list_item_active':tabbarIndex==2}">
        内饰
        <template v-if="detail.nscount">({{detail.nscount}})</template>
      </span>
    </div>
    <div class="ml_tabbar_list_item" @click="goToTab('jsTab',3)">
      <span :class="{'ml_tabbar_list_item_active':tabbarIndex==3}">
        机电
        <template v-if="detail.jdcount">({{detail.jdcount}})</template>
      </span>
    </div>
    <div class="ml_tabbar_list_item" @click="goToTab('szclTab',4)">
      <span :class="{'ml_tabbar_list_item_active':tabbarIndex==4}">
        水渍残留点
        <template v-if="detail.szclcount">({{detail.szclcount}})</template>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabbarIndex: 0,
      isShowTabBar: false
    }
  },
  props: {
    detail: {
      type: Object,
      default: null
    }
  },
  created() {
    this.listenerFunction()
  },
  methods: {
    listenerFunction(e) {
      document.addEventListener('scroll', this.handleScroll, true)
    },
    handleScroll() {
      //   console.log(window.pageYOffset)
      //   滚动到车况检测
      if (window.pageYOffset > 960) {
        this.isShowTabBar = true
      } else {
        this.isShowTabBar = false
      }

      if (window.pageYOffset >= document.querySelector('#wgTab').offsetTop - 50) {
        this.tabbarIndex = 0
      }

      if (window.pageYOffset >= document.querySelector('#gjTab').offsetTop - 50) {
        this.tabbarIndex = 1
      }

      if (window.pageYOffset >= document.querySelector('#nsTab').offsetTop - 50) {
        this.tabbarIndex = 2
      }

      if (window.pageYOffset >= document.querySelector('#jsTab').offsetTop - 50) {
        this.tabbarIndex = 3
      }

      if (window.pageYOffset >= document.querySelector('#szclTab').offsetTop - 50) {
        this.tabbarIndex = 4
      }
    },
    goToTab(tab, index) {
      document.removeEventListener('scroll', this.handleScroll, true)

      this.tabbarIndex = index
      let _top = document.querySelector('#' + tab).offsetTop - 50

      window.scrollTo({
        top: _top,
        behavior: 'smooth'
      })

      setTimeout(() => {
        this.listenerFunction()
      }, 500)
    }
  }
}
</script>
<style lang="scss">
.ml_tabbar_list {
  // display: flex;
  // text-align: center;
  position: sticky;
  width: 100%;
  left: 0;
  top: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  z-index: 99;
  white-space: nowrap;
  overflow-x: auto;

  .ml_tabbar_list_item {
    // flex: 1;
    padding: 10px;
    word-break: keep-all;
    font-size: 14px;
    display: inline-block;
  }
  .ml_tabbar_list_item_active {
    padding: 10px 0;
    border-bottom: 1px solid #ff7901;
    color: #ff7901;
  }

  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 0; /*高宽分别对应横竖滚动条的尺寸*/
    height: 0;
  }
}
</style>
