<!--
 * @名称: PC 检测信息
 * @Descripttion: ml 创建
 * @Author: ml
 * @Date: 2019-12-16 11:50:39
 * @LastEditors  : min
 * @LastEditTime : 2022-11-07 16:28:05
 -->

<template>
  <div class="ml_pc_report">
    <el-row :gutter="10">
      <el-col :span="12">
        <el-carousel :autoplay="false" indicator-position="none" arrow="always" ref="carouse" @change="onChangeImg">
          <template v-if="videoId">
            <el-carousel-item>
              <div class="video_cover_box">
                <div class="video_cover_img" :style="'background-image:url('+videoCoverImg+')'">
                  <img @click="playVideo()" class="video_cover_icon" src="../../assets/009.png" alt="">
                </div>
              </div>
              <div class="ml_pc_report_imgfooter">视频</div>
            </el-carousel-item>
          </template>
          <el-carousel-item v-for="(item, index) in climages" :key="index">
            <img :src="item.cfg_att_url + '?x-oss-process=image/resize,w_640,h_480/quality,q_90'"
              :alt="item.cfg_att_name" class="ml_report_carimg" @click="onPreview(climages, index)">
            <div class="ml_pc_report_imgfooter" v-if="item.cfg_att_name">
              {{ item.cfg_att_name }}
            </div>
          </el-carousel-item>

          <div class="indicator-box">
            <div class="custom-indicator">
              <template v-if="videoId">
                <div class="video-indicator"  @click="swipeVideo()" :class="{'indicator-active':current==0}">
                  <img src="../../assets/010.png" class="ml_img_icon" />视频
                </div>
                <div class="img-indicator" @click="swipeImage()" :class="{'indicator-active':current>0}">
                  <img src="../../assets/icon.png" class="ml_img_icon" />{{ current>1?current:1 }}/{{ climages.length }}
                </div>
              </template>
              <template v-else>
                <div class="img-indicator">
                  <img src="../../assets/icon.png" class="ml_img_icon" />{{ current + 1 }}/{{ climages.length }}
                </div>
              </template>
            </div>
            <el-row class="ml_pc_report_detection">
              <el-col :span="6" class="ml_pc_report_detection_title">综合</el-col>
              <el-col :span="6" class="ml_pc_report_detection_title">外观</el-col>
              <el-col :span="6" class="ml_pc_report_detection_title">骨架</el-col>
              <el-col :span="6" class="ml_pc_report_detection_title">内饰</el-col>
              <el-col :span="6" class="ml_pc_report_detection_value">{{ carlevel }}</el-col>
              <el-col :span="6" class="ml_pc_report_detection_value">{{ appearancescore }}</el-col>
              <el-col :span="6" class="ml_pc_report_detection_value">{{ skeletonscore }}</el-col>
              <el-col :span="6" class="ml_pc_report_detection_value">{{ interiorscore }}</el-col>
            </el-row>
          </div>
        </el-carousel>
      </el-col>
      <el-col :span="12">
        <el-col :span="24" class="ml_pc_report_name">
          {{ vehicle_name }}
        </el-col>
        <el-col :span="24">
          <el-row class="ml_pc_report_carinfo">
            <el-col :span="6">
              <div>{{ plate_number }}</div>
              <div>车牌</div>
            </el-col>
            <el-col :span="6">
              <div>{{ date_register }}</div>
              <div>注册日期</div>
            </el-col>
            <el-col :span="6">
              <div>{{ mileage }}</div>
              <div>表显里程</div>
            </el-col>
            <el-col :span="6">
              <div>{{ color_body }}</div>
              <div>车身颜色</div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="24" class="ml_pc_report_caraddress">
          门店名称：{{ erpsubname }}
        </el-col>
        <el-col :span="24" class="ml_pc_report_caraddress">
          门店联系人：{{ erpsubcontant }}
        </el-col>
        <el-col :span="24" class="ml_pc_report_caraddress">
          联系电话：{{ contantphone }}
        </el-col>
        <el-col :span="24" class="ml_pc_report_caraddress">
          验车详细地址：{{ detection_province }}{{ detection_city }}{{ detection_area }}{{ detection_address }}
        </el-col>
        <el-col :span="24" class="ml_pc_report_caraddress">
          检测开始时间：{{ starttime || '-' }}
        </el-col>
        <el-col :span="24" class="ml_pc_report_caraddress">
          检测结束时间：{{ endtime || '-' }}
        </el-col>
      </el-col>
    </el-row>

    <el-row>
      <el-col :span="12" class="ml_pc_report_title">
        基本信息
      </el-col>
      <el-col :span="12" style="text-align:right;">
        <el-button v-if="reportData && reportData.version !== '3.0'" type="text" @click="dialogVisible = true">查看更多参数<i
            class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
      </el-col>
      <el-col :span="24">
        <el-row :gutter="20">
          <el-col :span="8">
            <table class="ml_pc_report_table">
              <tr v-for="(item, index) in carBaseArray[0]" :key="index">
                <td>{{ item.text }}</td>
                <td> {{ item.value }}</td>
              </tr>
            </table>
          </el-col>
          <el-col :span="8">
            <table class="ml_pc_report_table">
              <tr v-for="(item, index) in carBaseArray[1]" :key="index">
                <td>{{ item.text }}</td>
                <td> {{ item.value }}</td>
              </tr>
            </table>
          </el-col>
          <el-col :span="8">
            <table class="ml_pc_report_table">
              <tr v-for="(item, index) in carBaseArray[2]" :key="index">
                <td>{{ item.text }}</td>
                <td> {{ item.value }}</td>
              </tr>
            </table>
          </el-col>
        </el-row>
      </el-col>

      <!-- 检测报告 1.0 -->
      <detectionReport :reportData="reportData" v-if="reportVersion === 1"></detectionReport>

      <!-- 检测报告 2.0 -->
      <detectionReport2 :reportData="reportData" v-if="reportVersion === 2"></detectionReport2>

    </el-row>

    <!-- 更多配置 -->
    <el-dialog :visible.sync="dialogVisible" width="1200px">
      <el-tabs type="card" class="ml_pc_detection_card">
        <template v-if="bzmodel">
          <template v-for="(item, index) in bzmodel">
            <el-tab-pane :label="item.carmodelconfigitemtypename" :key="index">
              <el-row class="ml_pc_detection_row">
                <template v-for="(items, indexs) in item.configmodel">
                  <el-col :span="8" :key="indexs">
                    <el-col :span="12">{{ items.carmodelconfigitemname }}</el-col>
                    <el-col :span="12">{{ items.carmodelconfigitemvalue }}</el-col>
                  </el-col>
                </template>
              </el-row>
            </el-tab-pane>
          </template>
        </template>

        <el-tab-pane label="选装/改装">
          <el-row class="ml_pc_detection_row">
            <template v-if="xzmodel">
              <template v-for="(items, indexs) in xzmodel">
                <el-col :span="8" :key="indexs">
                  <el-col :span="12">{{ items.cfg_item_name }}</el-col>
                  <el-col :span="12"><i class="el-icon-success" style="color:#ff7901;"></i></el-col>
                </el-col>
              </template>
            </template>
            <template v-if="gzmodel">
              <template v-for="(items, indexs) in gzmodel">
                <el-col :span="8" :key="indexs">
                  <el-col :span="12">{{ items.cfg_item_name }}</el-col>
                  <el-col :span="12"><i class="el-icon-success" style="color:#ff7901;"></i></el-col>
                </el-col>
              </template>
            </template>
          </el-row>
        </el-tab-pane>

      </el-tabs>
    </el-dialog>

    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="viewerArray" />

    <componentVideo ref="componentVideoRef" :videoId="videoId" :reportId="reportId" :videoCoverImg="videoCoverImg" from="pc"></componentVideo>

  </div>
</template>

<script>
import axios from 'axios'

import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import detectionReport from '@/components/component_pc/component_detectionReport_pc'
import detectionReport2 from '@/components/component_pc/component_detectionReport_pc_2.0'
import componentVideo from '@/components/component_mobile/component_video'

export default {
  components: {
    ElImageViewer,
    detectionReport,
    detectionReport2,
    componentVideo
  },
  data() {
    return {
      secret: '',
      timespan: '',

      current: 0,
      climages: [],
      vehicle_name: '',
      erpsubaddress: '',
      erpsubname: '',
      erpsubcontant: '',
      contantphone: '',
      detection_province: '',
      detection_city: '',
      detection_area: '',
      detection_address: '',

      plate_number: '',
      mileage: '',
      color_body: '',
      date_register: '',

      carBaseArray: [[], [], []],
      carlevel: '',
      skeletonscore: '',
      appearancescore: '',
      interiorscore: '',

      json: '',

      showViewer: false,
      viewerArray: [],

      dialogVisible: false,
      bzmodel: [], //  标准配置
      xzmodel: [], //  选装配置
      gzmodel: [], //  改装配置

      reportData: null,
      reportVersion: null,
      starttime: '',
      endtime: '',

      videoCoverImg:'',    // 视频封面
      videoId:'',
      reportId:'',
    }
  },
  mounted() {
    this.secret = this.$route.query.secret
    this.timespan = this.$route.query.timespan
    this.getCarDetail()
  },
  methods: {
    playVideo(){
      this.$refs.componentVideoRef.getVideoDetail(); 
    },
    onChangeViewImg(i) {
      this.viewChangeIndex = i
      this.viewChangeInfo = this.viewImgs[i]
    },
    swipeVideo(){
      if(this.current == 0){
        return
      }
      this.$refs.carouse.setActiveItem(0)
    },
    swipeImage(){
      if(this.current > 0){
        return
      }
      this.$refs.carouse.setActiveItem(1)
    },
    onChangeImg(i) { 
      this.current = i
    },
    onPreview(climages, i) {
      this.viewerArray = []
      let _img = []
      climages.forEach((element, index) => {
        if (index >= i) {
          this.viewerArray.push(
            element.cfg_att_url +
            '?x-oss-process=image/resize,w_1280,h_960/quality,q_90'
          )
        } else {
          _img.push(
            element.cfg_att_url +
            '?x-oss-process=image/resize,w_1280,h_960/quality,q_90'
          )
        }
      })
      this.viewerArray = this.viewerArray.concat(_img)
      this.showViewer = true
    },
    closeViewer() {
      this.showViewer = false
    },
    getCarDetail() {
      const that = this

      let jsonStr = ''

      jsonStr += 'carjson/' + that.timespan.substr(0, 4)
      jsonStr += that.timespan.substr(5, 2) + '/'
      jsonStr += that.timespan.substr(8, 2) + '/'
      jsonStr += that.secret + '.json'

      axios
        .get(process.env.VUE_APP_Images_API + jsonStr)
        .then(function (res) {
          if (res.data) {
            if(res.data.video_id){
              that.videoId = res.data.video_id
              res.data.climages.map(e=>{
                if(e.cfg_att_code == 'c02'){
                  that.videoCoverImg = e.cfg_att_url
                }
              })
            }

            that.climages = res.data.climages
            that.vehicle_name = res.data.vehicle_name || '-'
            that.erpsubaddress = res.data.erpsubaddress || '-'

            that.erpsubname = res.data.erpsubname || '-'
            that.erpsubcontant = res.data.erpsubcontant || '-'
            that.contantphone = res.data.contantphone || '-'
            that.detection_province = res.data.detection_province || '-'
            that.detection_city = res.data.detection_city || '-'
            that.detection_area = res.data.detection_area || '-'
            that.detection_address = res.data.detection_address || '-'

            that.plate_number = res.data.plate_number || '-'
            if (res.data.date_register) {
              that.date_register = res.data.date_register.replace(/-/g, '.')
            } else {
              that.date_register = '-'
            }

            if (res.data.mileage) {
              that.mileage = res.data.mileage / 10000 + '万公里'
            } else {
              that.mileage = '-'
            }

            that.color_body = res.data.color_body

            that.carBaseArray[0].push({
              text: 'VIN码',
              value: res.data.vehicle_vin || '-'
            })

            that.carBaseArray[0].push({
              text: '车牌所在地',
              value:
                (res.data.plate_province || '-') + (res.data.plate_city || '-')
            })

            that.carBaseArray[0].push({
              text: '使用性质',
              value: res.data.use_character || '-'
            })
            that.carBaseArray[0].push({
              text: '客车类型',
              value: res.data.bus_type_name || '-'
            })

            that.carBaseArray[0].push({
              text: '车主类型',
              value: res.data.onwer_type || '-'
            })

            that.carBaseArray[0].push({
              text: '品牌类型',
              value: res.data.brand || '-'
            })

            that.carBaseArray[1].push({
              text: '排放标准',
              value: res.data.emission_standard || '-'
            })

            that.carBaseArray[1].push({
              text: '座椅颜色',
              value: res.data.color_chair || '-'
            })
            that.carBaseArray[1].push({
              text: '购置税',
              value: res.data.car_tax_name || '-'
            })

            // that.carBaseArray[1].push({
            //   text: '注册日期',
            //   value: res.data.date_register
            //     ? res.data.date_register.replace(/-/g, '.')
            //     : '-'
            // })

            that.carBaseArray[1].push({
              text: '出厂日期',
              value: res.data.date_manufacture
                ? res.data.date_manufacture.replace(/-/g, '.')
                : '-'
            })

            that.carBaseArray[1].push({
              text: '原厂钥匙数',
              value: (res.data.original_key||res.data.original_key==0) ? res.data.original_key + '把' : '-'
            })

            // that.carBaseArray[1].push({
            //   text: '-',
            //   value: '-'
            // })

            that.carBaseArray[1].push({
              text: '曾经为租赁或汽车服务公司车辆',
              value: res.data.isrentalorcompany || '-'
            })

            that.carBaseArray[2].push({
              text: '年检到期',
              value: res.data.date_inspectionname
                ? res.data.date_inspectionname.replace(/-/g, '.')
                : res.data.date_inspection
            })

            that.carBaseArray[2].push({
              text: '交强险到期',
              value: res.data.date_clivtaname
                ? res.data.date_clivtaname.replace(/-/g, '.')
                : res.data.date_clivta
            })
            that.carBaseArray[2].push({
              text: '商业险有效期',
              value: res.data.business_insurance_date||'-'
            })
            that.carBaseArray[2].push({
              text: '产证变更记录',
              value: res.data.certificate_record
                ? res.data.certificate_record
                : ''
            })
            that.carBaseArray[2].push({
              text: '过户次数',
              value: res.data.certificate_record_num
                ? res.data.certificate_record_num + '次'
                : '-'
            })

            that.carBaseArray[2].push({
              text: '办证状态',
              value: res.data.certificate || '-'
            })

            // that.carBaseArray[2].push({
            //   text: '-',
            //   value: '-'
            // })
            // that.carBaseArray.push({
            //   text: "是否限购城市",
            //   value: res.data.isxgcsname || "-"
            // })

            that.skeletonscore = res.data.skeletonscore || '-'
            that.appearancescore = res.data.appearancescore || '-'
            that.interiorscore = res.data.interiorscore || '-'
            that.reportId = res.data.reportid

            that.bzmodel = res.data.bzmodel
            that.xzmodel = res.data.xzmodel
            that.gzmodel = res.data.gzmodel

            that.json = res.data.detection_report || ''



            if (that.json) {
              that.getDetectionDetail()
            }
          }
        })
        .catch(err => { console.log(err) })
    },
    getDetectionDetail() {
      const that = this
      axios
        .get(this.json)
        .then(function (res) {

          console.log('PC_get_det:', res.data.starttime, res.data.endtime)

          that.reportData = res.data;
          that.carlevel = res.data.carlevel || '-';
          that.starttime = res.data.starttime || '';
          that.endtime = res.data.endtime || '';


          if (that.reportData) {
            if (that.reportData.version === '3.0') {
              that.reportVersion = 2
            } else if (that.reportData.version === '2.0') {
              that.reportVersion = 2
            } else {
              that.reportVersion = 1
            }
          } else {
            that.reportVersion = 1
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>
<style lang="scss" scope>
.ml_pc_report {
  padding: 10px 0 50px 0;
  width: 1200px;
  margin: 0 auto;

  .prism-player{
    z-index: 11;
  }

  .el-carousel__item {
    .ml_pc_report_imgfooter {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 5px 0;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
    }
  }

  .video_cover_box{
    width: 100%;
    height: 100%;
    position: relative;
    .video_cover_img{
      width: 100%;
      height: 100%;
      position: absolute;
      top:0;
      left:0;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      .video_cover_icon{
        width: 36px;
        height: 36px;
      }
    }
  }

  .indicator-box{
    width: calc(100% - 20px);
    position: absolute;
    left: 10px;
    bottom: 40px;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    .custom-indicator {
      width: 120px;
      margin-right: 5px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      .img-indicator{
        padding:5px 6px;
        color: #fff;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 2px;
        line-height: 16px;
        .ml_img_icon {
          width: 12px;
          display: inline-block;
          vertical-align: middle;
          font-size: 0;
          margin-top: -3px;
          margin-right: 5px;
        }
      }
      .video-indicator{
        padding: 5px 6px;
        color: #fff;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 2px;
        line-height: 16px;
        margin-right: 5px;
        .ml_img_icon {
          width: 12px;
          display: inline-block;
          vertical-align: middle;
          font-size: 0;
          margin-top: -3px;
          margin-right: 5px;
        }
      }
      .indicator-active{
        background: #FF6600;
      }
    }
  }

  .ml_pc_report_detection {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    border-radius: 6px;
    position: static;
    width: calc( 100% - 135px);
    .ml_pc_report_detection_title {
      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;
      padding: 5px;

      &:nth-child(4n + 4) {
        border-right: 0;
      }
    }

    .ml_pc_report_detection_value {
      border-right: 1px solid #fff;
      padding: 5px 0;
      color: #f1cc32;

      &:last-child {
        border: 0;
      }
    }
  }

  .ml_report_carimg {
    object-fit: cover;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .ml_report_carimg_detection {
    width: 100%;
    cursor: pointer;
    height: 100%;
  }

  .ml_pc_report_name {
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0 20px 0;
  }

  .ml_pc_report_carinfo {
    background-color: #f8f8f8;
    padding: 15px 20px;
    text-align: center;
    margin: 20px 0;

    .el-col-6 {
      position: relative;

      &::after {
        content: "|";
        position: absolute;
        right: 0;
        top: 50%;
        color: #d3d3d3;
        font-size: 22px;
        font-weight: 50;
        margin-top: -11px;
      }

      &:last-child {
        &::after {
          content: "";
        }
      }

      div {
        &:first-child {
          padding-bottom: 10px;
        }

        &:last-child {
          color: #8d8d8d;
          font-size: 12px;
        }
      }
    }
  }

  .ml_pc_report_caraddress {
    // color: #8D8D8D;
    // padding: 10px 0 20px 0;
    padding: 10px 0 12px 0;
  }

  .ml_pc_report_title {
    padding: 20px 0;
    font-size: 16px;

    &::before {
      content: "▌";
      color: #ff7901;
    }
  }

  .ml_detection_level_list {
    width: 50%;
    display: flex;
    color: #ff7901;
    text-align: center;
    margin: 0 auto;
    border: 1px solid #ff7901;
    border-radius: 3px;

    .ml_detection_level_item {
      flex: 1;
      padding: 5px 0;
      border-right: 1px solid #ff7901;

      &:last-child {
        border: 0;
      }
    }
  }

  .ml_pc_report_table {
    border-collapse: collapse;
    text-align: center;
    width: 100%;

    tr {
      &:nth-child(2n + 2) {
        background-color: #f8f8f8;
      }

      td {
        padding: 15px 0;
        border: 1px solid #f1f1f1;
        line-height: 14px;
      }
    }
  }

  .el-icon-circle-close:before {
    color: #fff;
    font-size: 24px;
  }

  .ml_pc_report_content_title {
    background-color: #f6f6f6;
    padding: 10px 20px;
    margin-top: 20px;
  }

  .ml_pc_detection_img {
    position: relative;

    img {
      width: 100%;
    }

    .ml_circle {
      width: 12px;
      height: 12px;
      text-align: center;
      border-radius: 100%;
      position: absolute;
      color: #fff;
      cursor: pointer;
    }
  }

  .ml_detection_color_list {
    padding: 20px 0 10px 0;
    display: flex;
    color: #7d7d7d;

    .ml_detection_color_list_item {
      flex: 1;
      text-align: center;

      .ml_color_circle {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        margin-right: 10px;
        display: inline-block;
      }
    }
  }

  .el-carousel__container {
    height: 438.75px;
  }

  .ml_detection_name {
    text-align: center;
    padding: 5px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
  }

  .ml_dot_detection {
    position: absolute;
    z-index: 9999;
    margin-top: -9px;
    margin-left: -5px;

    .ml_dot_color {
      color: #797979;

      &::before {
        content: "●";
        font-size: 16px;
        padding-right: 8px;
      }
    }

    .ml_dot_detection_text {
      position: absolute;
      left: 15px;
      margin-top: -22px;
    }

    .ml_dot_triangle {
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-right: 13px solid rgba(0, 0, 0, 0.8);
        border-bottom: 13px solid transparent;
        display: inline-block;
      }
    }

    .ml_dot_text {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
      height: 26px;
      line-height: 26px;
      display: inline-block;
      white-space: nowrap;
      padding: 0 8px;
      margin-left: 13px;
    }
  }

  .ml_dot_detection1 {
    position: absolute;
    z-index: 9999;

    .ml_dot_color {
      color: #797979;

      &::before {
        content: "●";
        font-size: 16px;
        padding-left: 8px;
      }
    }

    .ml_dot_detection_text {
      position: absolute;
      right: 30px;
      margin-top: -22px;
    }

    .ml_dot_triangle {
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-left: 13px solid rgba(0, 0, 0, 0.8);
        border-bottom: 13px solid transparent;
        display: inline-block;
      }
    }

    .ml_dot_text {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
      height: 26px;
      line-height: 26px;
      display: inline-block;
      white-space: nowrap;
      padding: 0 8px;
    }
  }

  .ml_pc_detection_card {
    .ml_pc_detection_row {
      border-top: 1px solid #f1f1f1;
      border-left: 1px solid #f1f1f1;
    }

    .el-col-8 {
      border-bottom: 1px solid #f1f1f1;
      border-right: 1px solid #f1f1f1;

      .el-col-12 {
        padding: 10px;

        &:first-child {
          background-color: #f8f8f8;
        }
      }
    }
  }

  .ml_circle_outside {
    position: relative;
    width: 100%;
    height: 100%;

    .ml_circle_inside {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 12px;
      height: 12px;
      filter: alpha(opacity=30);
      -moz-opacity: 0.3;
      -khtml-opacity: 0.3;
      opacity: 0.3;
      border-radius: 100%;
      animation: mymove 1.5s infinite;
      -webkit-animation: mymove 1.5s infinite;
    }

    @keyframes mymove {
      from {
        width: 12px;
        height: 12px;
      }

      to {
        width: 24px;
        height: 24px;
      }
    }

    @-webkit-keyframes mymove {
      from {
        width: 12px;
        height: 12px;
      }

      to {
        width: 24px;
        height: 24px;
      }
    }
  }

  .ml_view_img {
    .el-carousel__container {
      height: 585px;
    }

    .el-dialog__body,
    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__headerbtn {
      right: -30px;
      color: #fff;
      top: 0;

      .el-icon-close:before {
        color: #fff;
        font-size: 20px;
      }
    }

    .ml_view_img_dialog_footer {
      text-align: left;
    }

    .ml_view_img_name {
      padding: 10px 0;

      .el-col {
        font-size: 18px;
      }
    }

    .ml_view_img_title {
      display: inline-block;
      padding: 3px 10px;
      color: #fff;
      border-radius: 3px;
      font-size: 12px;
    }

    .ml_view_img_value {
      display: inline-block;
      padding: 3px 10px;
      margin: 5px;
      font-size: 12px;
      color: #858585;
    }
  }

  .ml_pc_detection_list {
    background-color: #f8f8f8;
    padding: 10px 20px;

    .el-col-4 {
      padding: 10px 0;
      cursor: pointer;
    }
  }
}

.ml_pc_detection_list_popover {
  color: #fff;

  .el-carousel__container {
    height: 438.75px;
  }

  .ml_report_carimg_detection {
    width: 100%;
    height: 100%;
  }

  .ml_detection_name {
    text-align: center;
    padding: 5px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
  }

  .ml_dot_detection {
    position: absolute;
    z-index: 9999;
    margin-top: -9px;
    margin-left: -5px;

    .ml_dot_color {
      color: #797979;

      &::before {
        content: "●";
        font-size: 16px;
        padding-right: 8px;
      }
    }

    .ml_dot_detection_text {
      position: absolute;
      left: 15px;
      margin-top: -22px;
    }

    .ml_dot_triangle {
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-right: 13px solid rgba(0, 0, 0, 0.8);
        border-bottom: 13px solid transparent;
        display: inline-block;
      }
    }

    .ml_dot_text {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
      height: 26px;
      line-height: 26px;
      display: inline-block;
      white-space: nowrap;
      padding: 0 8px;
      margin-left: 13px;
    }
  }

  .ml_dot_detection1 {
    position: absolute;
    z-index: 9999;

    .ml_dot_color {
      color: #797979;

      &::before {
        content: "●";
        font-size: 16px;
        padding-left: 8px;
      }
    }

    .ml_dot_detection_text {
      position: absolute;
      right: 30px;
      margin-top: -22px;
    }

    .ml_dot_triangle {
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-left: 13px solid rgba(0, 0, 0, 0.8);
        border-bottom: 13px solid transparent;
        display: inline-block;
      }
    }

    .ml_dot_text {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
      height: 26px;
      line-height: 26px;
      display: inline-block;
      white-space: nowrap;
      padding: 0 8px;
    }
  }
}
</style>
