<!--
 * @名称: mobile检测信息
 * @Descripttion: ml 创建
 * @Author: ml
 * @Date: 2019-12-16 11:50:36
 * @LastEditors  : min
 * @LastEditTime : 2022-11-30 10:22:43
 -->
<template>
  <div class="ml_mobile_report">
    <!-- 导航组建 -->
    <componenttab :detail="{ wgcount, gjcount, nscount, jdcount, szclcount }"></componenttab>
    <van-swipe indicator-color="white" @change="onChange" ref="carImageSwipe">
      <van-swipe-item v-if="videoId">
        <div class="video_cover_box" :style="'width:'+imgWidth+'px;height:'+imgHeight+'px'">
          <div class="video_cover_img" :style="'width:'+imgWidth+'px;height:'+imgHeight+'px;background-image:url('+videoCoverImg+')'">
            <img @click="playVideo()" class="video_cover_icon" src="../../assets/009.png" alt="">
          </div>
        </div>
      </van-swipe-item>
      <van-swipe-item @click="imgPreview(index)" v-for="(item, index) in climages" :key="index">
        <van-image :width="imgWidth" fit="cover" :height="imgHeight"
          :src="item.cfg_att_url + '?x-oss-process=image/resize,w_640,h_480/quality,q_90'">
          <template v-slot:loading>
            <van-loading type="spinner" size="20" />
          </template>
          <template v-slot:error>加载失败</template>
        </van-image>
      </van-swipe-item>
      <template slot="indicator">
        <div class="indicator-box">
          <div class="custom-indicator">
            <template v-if="videoId">
              <div class="video-indicator" @click="swipeVideo()" :class="{'indicator-active':current==0}">
                <img src="../../assets/010.png" class="ml_img_icon" />视频
              </div>
              <div class="img-indicator" @click="swipeImage()" :class="{'indicator-active':current>0}">
                <img src="../../assets/icon.png" class="ml_img_icon" />{{ current>1?current:1 }}/{{ climages.length }}
              </div>
            </template>
            <template v-else>
              <div class="img-indicator">
                <img src="../../assets/icon.png" class="ml_img_icon" />{{ current + 1 }}/{{ climages.length }}
              </div>
            </template>
          </div>
          <van-row class="ml_curtomer_table" gutter="10">
            <van-col span="6" class="ml_curtomer_table_title" @click="showExplainPopu(3)">综合<img class="ml_icon_img"
                src="../../assets/008.png" /></van-col>
            <van-col span="6" class="ml_curtomer_table_title" @click="showExplainPopu(0)">外观<img class="ml_icon_img"
                src="../../assets/008.png" /></van-col>
            <van-col span="6" class="ml_curtomer_table_title" @click="showExplainPopu(1)">骨架<img class="ml_icon_img"
                src="../../assets/008.png" /></van-col>
            <van-col span="6" class="ml_curtomer_table_title" @click="showExplainPopu(2)">内饰<img class="ml_icon_img"
                src="../../assets/008.png" /></van-col>
            <van-col span="6" class="ml_curtomer_table_value">{{ carlevel }}</van-col>
            <van-col span="6" class="ml_curtomer_table_value">{{ appearancescore }}</van-col>
            <van-col span="6" class="ml_curtomer_table_value">{{ skeletonscore }}</van-col>
            <van-col span="6" class="ml_curtomer_table_value">{{ interiorscore }}</van-col>
          </van-row>
        </div>
      </template>
    </van-swipe>

    <div class="ml_carinfo">
      <div class="ml_carname">{{ vehicle_name }}</div>
      <div>{{ vehicle_vin }}</div>
      <!-- <div class="ml_carbase">
        {{erpsubaddress}}
      </div> -->

      <div style="padding-top: 10px;">检测开始时间：{{ starttime || '-' }}</div>
      <div style="padding-top: 10px;">检测结束时间：{{ endtime || '-' }}</div>

      <van-row class="ml_car_info">
        <van-col span="6">
          <div class="ml_car_info_title">车牌</div>
          <div class="ml_car_info_value">{{ plate_number }}</div>
        </van-col>
        <van-col span="6">
          <div class="ml_car_info_title">表显里程</div>
          <div class="ml_car_info_value">{{ mileage }}</div>
        </van-col>
        <van-col span="6">
          <div class="ml_car_info_title">车身颜色</div>
          <div class="ml_car_info_value">{{ color_body }}</div>
        </van-col>
        <van-col span="6">
          <div class="ml_car_info_title">注册日期</div>
          <div class="ml_car_info_value">{{ date_register }}</div>
        </van-col>
      </van-row>
    </div>

    <div class="ml_detection_line"></div>

    <van-cell size="large" :border="false">
      <template slot="title">
        <span class="custom-title-car">基本信息</span>
      </template>
      <template slot="label">
        <van-row gutter="10">
          <van-col span="24" v-for="(item, index) in carBaseArray" :key="index">
            <van-col span="8" v-for="(items, indexs) in item" :key="indexs">
              <div class="ml_car_baseinfo_title">{{ items.text }}</div>
              <div class="ml_car_baseinfo_value">{{ items.value }}</div>
            </van-col>
          </van-col>

          <van-col span="24" class="ml_car_baseinfo_more" @click="goToMoreConfig"
            v-if="reportData && reportData.version !== '3.0'">
            更多参数配置
            <img src="../../assets/006.png"
              style="width:15px;display:inline-block;vertical-align: middle;margin-left: 10px;" />
          </van-col>
        </van-row>
      </template>
    </van-cell>

    <div class="ml_detection_line"></div>

    <van-cell size="large" :border="false">
      <template slot="title">
        <span class="custom-title-car">车况检测</span>
      </template>
    </van-cell>

    <div class="ml_tab_list">
      <div class="ml_tab_item">
        <div>外观</div>
        <div> {{ appearancescore }}分</div>
      </div>
      <div class="ml_tab_item">
        <div>骨架</div>
        <div> {{ skeletonscore }}级</div>
      </div>
      <div class="ml_tab_item">
        <div>内饰</div>
        <div>{{ interiorscore }}分</div>
      </div>
      <div class="ml_tab_item">
        <div>机电</div>
        <div> {{ jdcount }}项</div>
      </div>
      <div class="ml_tab_item">
        <div>水渍残留点</div>
        <div>{{ szclcount }}项</div>
      </div>
    </div>

    <!-- 检测报告 1.0 -->
    <detectionReport :reportData="reportData" v-if="reportVersion === 1"></detectionReport>

    <!-- 检测报告 2.0 -->
    <detectionReport2 :reportData="reportData" v-if="reportVersion === 2"></detectionReport2>

    <!-- 图片预览 -->
    <van-image-preview v-model="showImgView" :images="sizeClimages" :start-position="startIndex" @change="onChangeView">
      <template v-slot:cover>
        {{ imgText }}
      </template>
    </van-image-preview>

    <!-- 更多参数配置 -->
    <van-popup v-model="showConfigPopu" closeable close-icon-position="top-left" position="right"
      :style="{ width: '100%', height: '100%' }">
      <van-tabs v-model="active" sticky>
        <van-tab title="标准配置">
          <div style="height:calc(100vh - 44px);overflow: auto;-webkit-overflow-scrolling: touch;">
            <template v-for="(item, index) in bzmodel">
              <van-cell-group :title="item.carmodelconfigitemtypename" :key="index">
                <template v-for="(items, indexs) in item.configmodel">
                  <van-cell :title="items.carmodelconfigitemname" :value="items.carmodelconfigitemvalue"
                    :key="indexs" />
                </template>
              </van-cell-group>
            </template>
          </div>
        </van-tab>
        <van-tab title="改装/选装配置">
          <div style="height:calc(100vh - 44px);overflow: auto;-webkit-overflow-scrolling: touch;">
            <van-cell-group title="选装配置">
              <template v-for="(item, index) in xzmodel">
                <van-cell :title="item.cfg_item_name" :key="index">
                  <van-icon slot="right-icon" name="checked" style="line-height: inherit;" color="#ff7901" />
                </van-cell>
              </template>
            </van-cell-group>

            <van-cell-group title="改装配置">
              <template v-for="(item, index) in gzmodel">
                <van-cell :title="item.cfg_item_name" :key="index">
                  <van-icon slot="right-icon" name="checked" style="line-height: inherit;" color="#ff7901" />
                </van-cell>
              </template>
            </van-cell-group>
          </div>
        </van-tab>
      </van-tabs>
    </van-popup>

    <componentRule ref="componentRule"></componentRule>

    <componentVideo ref="componentVideoRef" :videoId="videoId" :reportId="reportId" :videoCoverImg="videoCoverImg"></componentVideo>

  </div>
</template>
<script>
import axios from 'axios'

import detectionReport from '@/components/component_mobile/component_detectionReport'
import detectionReport2 from '@/components/component_mobile/component_detectionReport_2.0'
import componenttab from '@/components/component_mobile/component_tab'
import componentVideo from '@/components/component_mobile/component_video'

import componentRule from '@/components/component_mobile/component_rule_mobile'

export default {
  components: {
    detectionReport,
    detectionReport2,
    componenttab,
    componentVideo,
    componentRule,
  },
  data() {
    return {
      secret: '',
      timespan: '',

      current: 0,
      showImgView: false,
      startIndex: 0,
      climages: [],
      imgText: '',
      sizeClimages: [],
      imgWidth: window.screen.width,
      imgHeight: (window.screen.width * 3) / 4,

      vehicle_name: '',
      vehicle_vin: '',
      erpsubaddress: '',
      plate_number: '',
      mileage: '',
      color_body: '',
      date_register: '',

      carBaseArray: [],

      carlevel: '',
      skeletonscore: '',
      appearancescore: '',
      interiorscore: '',

      wgcount: '',
      gjcount: '',
      nscount: '',
      jdcount: '',
      szclcount: '',

      json: '',
      active: 0,
      reportData: null,
      reportVersion: null,

      showConfigPopu: false,
      bzmodel: [], //  标准配置
      xzmodel: [], //  选装配置
      gzmodel: [], //  改装配置
      starttime: '',
      endtime: '',

      videoCoverImg:'',    // 视频封面
      videoId:'',
      reportId:'',
    }
  },
  mounted() {
    this.secret = this.$route.query.secret
    this.timespan = this.$route.query.timespan

    this.getCarDetail()
  },
  methods: {
    playVideo(){
      this.$refs.componentVideoRef.getVideoDetail(); 
    },
    showExplainPopu(index) {
      this.$refs.componentRule.showExplainPopu(index)
    },
    swipeVideo(){
      if(this.current == 0){
        return
      }
      this.$refs.carImageSwipe.swipeTo(0)
    },
    swipeImage(){
      if(this.current > 0){
        return
      }
      this.$refs.carImageSwipe.swipeTo(1)
    },
    onChange(index) {
      this.current = index
    },
    onChangeView(i) {
      this.imgText = this.climages[i].cfg_att_name || ''
    },
    imgPreview(i) {
      this.startIndex = i
      this.imgText = this.climages[i].cfg_att_name || ''
      let _imgArray = []
      this.climages.forEach(element => {
        _imgArray.push(
          element.cfg_att_url +
          '?x-oss-process=image/resize,w_1280,h_960/quality,q_90'
        )
      })
      this.sizeClimages = _imgArray
      this.showImgView = true
    },
    getCarDetail() {
      const that = this

      let jsonStr = ''

      jsonStr += 'carjson/' + that.timespan.substr(0, 4)
      jsonStr += that.timespan.substr(5, 2) + '/'
      jsonStr += that.timespan.substr(8, 2) + '/'
      jsonStr += that.secret + '.json'

      axios
        .get(process.env.VUE_APP_Images_API + jsonStr)
        .then(function (res) {
          if (res.data) {

            if(res.data.video_id){
              that.videoId = res.data.video_id
              res.data.climages.map(e=>{
                if(e.cfg_att_code == 'c02'){
                  that.videoCoverImg = e.cfg_att_url
                }
              })
            }

            that.climages = res.data.climages
            that.vehicle_name = res.data.vehicle_name || '-'
            that.vehicle_vin = res.data.vehicle_vin || '-'
            that.erpsubaddress = res.data.erpsubaddress || '-'
            that.plate_number = res.data.plate_number || '-'
            if (res.data.date_register) {
              that.date_register = res.data.date_register
            } else {
              that.date_register = '-'
            }

            if (res.data.mileage) {
              that.mileage = res.data.mileage / 10000 + '万公里'
            } else {
              that.mileage = '-'
            }

            that.color_body = res.data.color_body

            let all_arr = [
              {
                text: '排放标准',
                value: res.data.emission_standard || '-'
              },
              {
                text: '座椅颜色',
                value: res.data.color_chair || '-'
              },
              {
                text: '购置税',
                value: res.data.car_tax_name || '-'
              },
              {
                 text: '使用性质',
                value: res.data.use_character || '-'
              },
              {
                text: '客车类型',
                value: res.data.bus_type_name || '-'
              },
              // {
              //   text: '注册日期',
              //   value: res.data.date_register ? res.data.date_register: '-' 
              // },
              {
                text: '出厂日期',
                value: res.data.date_manufacture ? res.data.date_manufacture.replace(/-/g, '.') : '-'
              },
              {
                text: '年检到期',
                value: res.data.date_inspectionname ? res.data.date_inspectionname : res.data.date_inspection
              },
              {
                text: '交强险到期',
                value: res.data.date_clivtaname ? res.data.date_clivtaname.replace(/-/g, '.') : res.data.date_clivta
              },
              {
                text: '商业险有效期',
                value: res.data.business_insurance_date||'-'
              },
              {
                text: '产证变更记录',
                value: res.data.certificate_record ? res.data.certificate_record : ''
              },
              {
                text: '过户次数',
                value: res.data.certificate_record_num ? res.data.certificate_record_num + '次' : '-'
              },
              {
                text: '办证状态',
                value: res.data.certificate || '-'
              },
              {
                text: '车牌所在地',
                value: (res.data.plate_province || '-') + (res.data.plate_city || '-')
              },
              // {
              //   text: '是否限购城市',
              //   value: res.data.isxgcsname || '-'
              // },
              {
                text: '车主类型',
                value: res.data.onwer_type || '-'
              },
              {
                text: '品牌类型',
                value: res.data.brand || '-'
              },
              {
                text: '原厂钥匙数',
                value: (res.data.original_key||res.data.original_key==0) ? res.data.original_key + '把' : '-'
              },
              {
                text: '曾经为租赁或汽车服务公司车辆',
                value: res.data.isrentalorcompany || '-'
              }
            ]
            for (var i = 0; i < all_arr.length; i += 3) {
              that.carBaseArray.push(all_arr.slice(i, i + 3));
            }

            that.skeletonscore = res.data.skeletonscore || '-'
            that.appearancescore = res.data.appearancescore || '-'
            that.interiorscore = res.data.interiorscore || '-'
            that.reportId = res.data.reportid

            that.bzmodel = res.data.bzmodel

            that.xzmodel = res.data.xzmodel || []
            that.gzmodel = res.data.gzmodel || []

            that.json = res.data.detection_report || ''

            if (that.json) {
              that.getDetectionDetail()
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    goToMoreConfig() {
      // 更多配置
      this.showConfigPopu = true
    },
    getDetectionDetail() {
      const that = this
      axios
        .get(this.json)
        .then(function (res) {
          if (res.data.data) {

            that.reportData = res.data;
            that.carlevel = res.data.carlevel || '-';
            that.starttime = res.data.starttime || '';
            that.endtime = res.data.endtime || '';

            if (that.reportData) {
              if (that.reportData.version === '3.0') {
                that.reportVersion = 2
              } else if (that.reportData.version === '2.0') {
                that.reportVersion = 2
              } else {
                that.reportVersion = 1
              }
            } else {
              that.reportVersion = 1
            }

            that.reportData.data.forEach(element => {
              switch (element.part_code) {
                case 'P01':
                  // 骨架
                  that.gjcount = element.e_count
                  break
                case 'P02':
                  // 外观
                  that.wgcount = element.e_count
                  break
                case 'P03':
                  // 内饰
                  that.nscount = element.e_count
                  break
                case 'M013':
                  // 机电
                  that.jdcount = element.e_count
                  break
                case '-1':
                  // 水渍残留点
                  that.szclcount = element.e_count
                  break
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

  }
}
</script>
<style lang="scss">
.ml_mobile_report {
  .van-swipe-item {
    .ml_cardetail_img {
      object-fit: cover;
      display: inline-block;
    }
  }

  .video_cover_box{
    position: relative;
    .video_cover_img{
      position: absolute;
      top:0;
      left:0;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      .video_cover_icon{
        width: 36px;
        height: 36px;
      }
    }
  }

  .indicator-box{
    width: calc(100% - 20px);
    position: absolute;
    left: 10px;
    bottom: 15px;
    display: flex;
    justify-content: space-between;
    .custom-indicator {
      width: 64px;
      margin-right: 5px;
      display: flex;
      flex-direction: column;
      justify-content: end;
      .img-indicator{
        padding:4px 6px;
        color: #fff;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 2px;
        line-height: 16px;
        .ml_img_icon {
          width: 12px;
          display: inline-block;
          vertical-align: middle;
          font-size: 0;
          margin-top: -3px;
          margin-right: 5px;
        }
      }
      .video-indicator{
        padding: 5px 6px;
        color: #fff;
        font-size: 12px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 2px;
        margin-bottom: 2px;
        line-height: 16px;
        .ml_img_icon {
          width: 12px;
          display: inline-block;
          vertical-align: middle;
          font-size: 0;
          margin-top: -3px;
          margin-right: 5px;
        }
      }
      .indicator-active{
        background: #FF6600;
      }
    }

    .ml_curtomer_table {
      width: calc( 100% - 70px);
      text-align: center;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      border-radius: 3px;

      .ml_curtomer_table_title {
        border-right: 1px solid rgba(255, 255, 255, 0.6);
        border-bottom: 1px solid rgba(255, 255, 255, 0.6);
        padding: 4px 0;

        &:nth-child(4n + 4) {
          border-right: 0;
        }
      }

      .ml_curtomer_table_value {
        border-right: 1px solid rgba(255, 255, 255, 0.6);
        color: #e6c135;
        padding: 4px 0;

        &:nth-child(4n + 4) {
          border-right: 0;
        }
      }
    }
  }

  .van-image-preview__cover {
    bottom: 10px;
    color: #fff;
    left: 0;
    width: 100%;
    text-align: center;
    top: unset;
  }

  .ml_carinfo {
    padding: 10px 16px;

    .ml_carname {
      font-size: 18px;
      font-weight: bold;
      padding-bottom: 10px;
    }

    .ml_carbase {
      color: #9a9a9a;
      padding-top: 10px;

      .ml_line_carbase {
        padding-right: 10px;

        &::after {
          content: "|";
          margin-left: 10px;
          font-size: 12px;
        }

        &:last-child {
          &::after {
            content: "";
          }
        }
      }
    }
  }

  .ml_car_info {
    background-color: #fafafa;
    border-radius: 3px;
    margin-top: 10px;
    border: 1px solid #e0e0e0;

    .ml_car_info_title {
      font-size: 12px;
      padding: 10px 0;
      text-align: center;
      color: #a4a4a4;
    }

    .ml_car_info_value {
      padding: 10px 0;
      text-align: center;
    }
  }

  .ml_car_baseinfo_title {
    padding: 10px 0 5px 0;
  }

  .ml_car_baseinfo_value {
    padding: 5px 0 10px 0;
    color: #212121;
  }

  .ml_car_baseinfo_more {
    border-top: 1px solid #f1f1f1;
    text-align: center;
    color: #212121;
    padding-top: 12px;
    margin-top: 10px;
  }

  .custom-title-car {
    font-size: 16px;
    font-weight: bold;
    padding-left: 4px;
  }

  .custom-label {
    padding: 16px;
    margin-top: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  }

  .ml_tab_list {
    padding: 10px 20px;
    display: flex;

    .ml_tab_item {
      flex: 1;
      text-align: center;
      padding: 5px 0;
      color: #ff7901;
      border: 1px solid #ff7901;

      &:nth-child(2n) {
        border-right: 0;
        border-left: 0;
      }

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      div {
        height: 30px;
        line-height: 30px;
        white-space: nowrap;

        &:first-child {
          border-bottom: 1px solid #ff7901;
        }
      }
    }
  }

  .ml_detection_line {
    border-bottom: 10px solid #f2f2f2;
  }

  .van-popup--center {
    width: 100%;
  }

  .van-collapse-item__content {
    padding: 0;
  }

  .van-tag--default.van-tag--plain {
    margin: 0 10px 0 0;
  }

  .ml_icon_img {
    display: inline-block;
    width: 12px;
    vertical-align: middle;
    margin-top: -3px;
    margin-left: 5px;
  }
}
</style>
