<!--
 * @名称: 检测报告 - 完整报告  兼容PC和mobile
 * @Descripttion: ml 创建
 * @Author: ml
 * @Date: 2019-12-16 11:57:55
 * @LastEditors  : ml
 * @LastEditTime : 2020-08-12 11:52:35
 -->
<template>
  <div>
    <detectionReportMobile v-if="isMobile"></detectionReportMobile>
    <detectionReportPC v-else></detectionReportPC>
  </div>
</template>
<script>
import detectionReportPC from '@/components/component_pc/detectionreport_pc'
import detectionReportMobile from '@/components/component_mobile/detectionreport_mobile'

export default {
  components: {
    detectionReportPC,
    detectionReportMobile
  },
  data () {
    let _isMobile = false
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      _isMobile = true
    } else {
      _isMobile = false
    }

    return {
      isMobile: _isMobile
    }
  },
  mounted () {
    const that = this
    window.onresize = function () {
      let _isMobile = false

      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        _isMobile = true
      } else {
        _isMobile = false
      }
      that.isMobile = _isMobile
    }
  }
}
</script>
